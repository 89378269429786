import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Spinner, Button } from "react-bootstrap";
import MasterLayout from "../master/MasterLayout";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UpComing = () => {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [upComingAppointments, setUpComingAppointments] = useState([]);

  const getAllUpComingAppointments = (accessToken) => {
    axios
      .get(
        "https://admin.cornwallsecuritygroup.com/api/appointments/getAppointmentDetails",
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data.appointments);
        setUpComingAppointments(response.data.appointments);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changeStatusOfAppointment = (appointment_id) => {
    const formData = new FormData();
    formData.append("appointment_id", `${appointment_id}`);
    axios({
      method: "post",
      url: `https://admin.cornwallsecuritygroup.com/api/appointments/changeStatusOfAppointment`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data.success);
        if (response.data.success == "Status changed successfully") {
          alert("Status changed successfully");
          getAllUpComingAppointments(accessToken);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("admin") == null) {
        navigate("/");
      } else {
        const admin = JSON.parse(localStorage.getItem("admin"));
        setAccessToken(admin.access_token);
        getAllUpComingAppointments(admin.access_token);
        setLoading(false);
      }
    }, 1000);
  }, []);

  return loading ? (
    <Row className="d-flex justify-content-lg-center">
      <Spinner style={{ width: "50px", height: "50px", marginTop: "20%" }} />
    </Row>
  ) : (
    <MasterLayout>
      <Container fluid className="m-0 p-0">
        <h1 className="poppins-semiBold">Up Coming Appoinments</h1>
        <Table
          responsive
          bordered
          striped
          size={isDesktopOrLaptop ? "lg" : "sm"}
          className=" my-4  "
        >
          <thead>
            <tr>
              <th className="poppins-semiBold">#</th>
              <th className="poppins-semiBold">Name</th>
              <th className="poppins-semiBold">Email</th>
              <th className="poppins-semiBold">Phone</th>
              <th className="poppins-semiBold">Date</th>
              <th className="poppins-semiBold">Time</th>
              <th className="poppins-semiBold">Message</th>
              <th className="poppins-semiBold">Action</th>
            </tr>
          </thead>
          <tbody>
            {upComingAppointments.map((item, index) => (
              <tr key={index} className="res-small-text">
                <td className="poppins-regular">{index + 1}</td>
                <td className="poppins-regular ">{item.name}</td>
                <td className="poppins-regular">{item.email}</td>
                <td className="poppins-regular">{item.phone}</td>
                <td className="poppins-regular">{item.date}</td>
                <td className="poppins-regular">{item.time}</td>
                <td className="poppins-regular">{item.message}</td>
                <td className="poppins-regular">
                  <Button
                    variant="success"
                    className="poppins-semiBold txt-white"
                    onClick={() => {
                      changeStatusOfAppointment(item.id);
                    }}
                  >
                    Mark As Complete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </MasterLayout>
  );
};

export default UpComing;
