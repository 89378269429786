import React, { useEffect, useState } from "react";
import SiteMasterLayout from "../../masterLayout/SiteMasterLayout";
import { Card, Col, Container, Row } from "react-bootstrap";
import MainServiceCard from "../../../components/cards/MainServiceCard";
import gaurds from "../../../assets/images/about3.jpg";
import axios from "axios";

const Services = () => {
  const [allServices, setAllServices] = useState([]);

  const getAllServices = () => {
    axios({
      method: "get",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/getAllServices`,
    })
      .then((response) => {
        console.log(response);
        console.log("//////////////////////////");
        console.log(response.data.services);
        setAllServices(response.data.services);
        console.log("//////////////////////////");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllServices();
  }, []);
  return (
    <SiteMasterLayout>
      <Container fluid className="background-container poppins-semiBold mb-5">
        <h1 className="pt-5 text-overlay ">Our Services</h1>
        <p className="pt-1 text-overlay poppins-medium footer-link-text ">
          Ensuring Your <span className="txt-yellow"> Security </span> with
          Unwavering <br />
          <span className="txt-yellow"> Confidence & Genuine </span>
          Care
        </p>
      </Container>
      <Container fluid className="content-container py-5 mt-5">
        <h2 className="poppins-semiBold">Professional Services</h2>
        <h6 className="poppins-medium txt-gray mt-2 ">
          Detect Deter Defend our clients Asset
        </h6>
        <Row className="my-5 px-2">
          {allServices.map((item, index) => {
            return (
              <Col lg={3} md={4} sm={12} className="pb-4">
                <MainServiceCard key={index} item={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </SiteMasterLayout>
  );
};

export default Services;
