import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ServiceModal from "../Modals/ServiceModal";

const MainServiceCard = ({ item }) => {
  const [isVisible, setIsVisible] = useState(false);
  const hideModel = () => {
    setIsVisible(false);
  };
  return (
    <>
      <Card className="main-card">
        {/* <Image className="main-card-img" src={carousal1} alt="Image" /> */}
        <Image
          className="main-card-img"
          src={`https://admin.cornwallsecuritygroup.com/storage/${item.image}`}
          alt="Image"
          // style={{ objectFit: "contain" }}
        />
        <Card.Body className={"px-3 py-0"}>
          <h6 className="poppins-semiBold mt-3 res-heading one-line-text ">{item.title}</h6>
          <p className="res-small-text">
            {item.description.substring(0, 60)}
            {" ... "}
            <Link
              className="res-readMore poppins-medium"
              onClick={() => {
                setIsVisible(true);
              }}
            >
              Read More
            </Link>
          </p>
        </Card.Body>
      </Card>
      <ServiceModal isVisible={isVisible} hideModel={hideModel} item={item} />
    </>
  );
};

export default MainServiceCard;
