import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './assets/css/fontStyles.css'
import { Route, Routes } from 'react-router-dom';
import Home from './layouts/site/home/Home';
import Dashboard from './layouts/admin/dashboard/Dashboard';
import Login from './layouts/admin/auth/Login';
import AboutUs from './layouts/site/aboutUs/AboutUs';
import Contactus from './layouts/site/contactus/Contactus';
import CertifiedGuard from './layouts/site/certifiedGuard/CertifiedGuard';
import Services from './layouts/site/services/Services';
import ServiceManagement from './layouts/admin/services/ServiceManagement';
import Contacts from './layouts/admin/allContacts/Contacts';
import UpComing from './layouts/admin/appointments/UpComing';
import Completed from './layouts/admin/appointments/Completed';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact-us" element={<Contactus />} />
      <Route path="/certified-guard" element={<CertifiedGuard />} />
      <Route path="/manage-services" element={<ServiceManagement />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/appointments-upcoming" element={<UpComing />} />
      <Route path="/appointments-completed" element={<Completed />} />

    </Routes>
    
  );
}

export default App;
