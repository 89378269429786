import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withSwal } from "react-sweetalert2";
import "../../../App.css";
import "../../../assets/css/AdminStyles.css";
import "../../../assets/css/fontStyles.css";

import axios from "axios";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaCalendarCheck, FaCalendarDay } from "react-icons/fa6";
import { MdMiscellaneousServices } from "react-icons/md";
import MasterLayout from "../master/MasterLayout";

const Dashboard = ({ swal }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loggedInAdmin, setLoggedInAdmin] = useState();
  const [totalServices, setTotalServices] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [upcomingAppointments, setUpcomingAppointments] = useState(0);
  const [completedAppointments, setCompletedAppointments] = useState(0);

  const getDashboardStats = (accessToken) => {
    axios
      .get("https://admin.cornwallsecuritygroup.com/api/dashboard/dashboardStats", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((resp) => {
       setTotalServices(resp.data.totalServices);
        setTotalContacts(resp.data.totalContacts);
        setUpcomingAppointments(resp.data.totalUpcomingAppointments);
        setCompletedAppointments(resp.data.totalCompletedAppointments);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("admin") == null) {
        navigate("/");
      } else {
        setLoggedInAdmin(JSON.parse(localStorage.getItem("admin")));
        const admin = JSON.parse(localStorage.getItem("admin"));
        getDashboardStats(admin.access_token);
        setLoading(false);
      }
    }, 1000);
  }, []);

  return loading ? (
    <Row className="d-flex justify-content-lg-center">
      <Spinner style={{ width: "50px", height: "50px", marginTop: "20%" }} />
    </Row>
  ) : (
    <MasterLayout>
      <Container className="m-0 p-0 " fluid>
        <h3 className="poppins-bold Heading-Text mt-5">Dashboard</h3>

        <Row className="px-2">
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">Total Services</h6>
                <h5 className="poppins-semiBold mt-4">{totalServices}</h5>
              </Container>
              <MdMiscellaneousServices size={35} className="Heading-Text" />
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">Contacts</h6>
                <h5 className="poppins-semiBold mt-4">{totalContacts}</h5>
              </Container>
              <BiSolidMessageSquareDetail size={20} className="Heading-Text" />
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">
                  Completed Appointments
                </h6>
                <h5 className="poppins-semiBold mt-2">
                  {completedAppointments}
                </h5>
              </Container>
              <FaCalendarCheck size={20} className="Heading-Text" />
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} className="py-3">
            <Card className="p-3 dashboard-card">
              <Container className="m-0 p-0">
                <h6 className="poppins-medium res-heading">
                  Upcoming Appointments
                </h6>
                <h5 className="poppins-semiBold mt-2">
                  {upcomingAppointments}
                </h5>
              </Container>
              <FaCalendarDay size={20} className="Heading-Text" />
            </Card>
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default withSwal(Dashboard);
