import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { withSwal } from "react-sweetalert2";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";

function AppNavbar({ swal }) {
  const logo = require("../../assets/images/security-logo.png");
  
  const navigate = useNavigate();
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="pt-4">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={logo}
              style={{ height: "30px", width: "150px", objectFit: "contain" }}
            />
            <span className="poppinsSemiBold White-Color-Text"> </span>
          </Navbar.Brand>
          <Navbar.Toggle className="" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link
                as={Link}
                to={"/dashboard"}
                className="mx-4 mt-1"
                style={{
                  color: "#171717",
                  fontFamily: "poppins-regular",
                  fontSize: "16px",
                }}
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={"/manage-services"}
                className="mx-4"
                style={{
                  color: "#171717",
                  fontFamily: "poppins-regular",
                  fontSize: "16px",
                }}
              >
                Services
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={"/appointments-upcoming"}
                className="mx-4"
                style={{
                  color: "#171717",
                  fontFamily: "poppins-regular",
                  fontSize: "16px",
                }}
              >
                Upcoming Appointments
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={"/appointments-completed"}
                className="mx-4"
                style={{
                  color: "#171717",
                  fontFamily: "poppins-regular",
                  fontSize: "16px",
                }}
              >
                Completed Appointments
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={"/contacts"}
                className="mx-4"
                style={{
                  color: "#171717",
                  fontFamily: "poppins-regular",
                  fontSize: "16px",
                }}
              >
                Contacts
              </Nav.Link>
              <Button
                style={{ textDecoration: "none", width: "100%" }}
                onClick={() => {
                  localStorage.removeItem("admin");
                  navigate("/");
                }}
                className="poppins-bold btn btn-sm btn-light"
              >
                Logout
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default withSwal(AppNavbar);
