import React from "react";
import { Card } from "react-bootstrap";
import { PiHandshakeLight } from "react-icons/pi";
import { RiUser3Line } from "react-icons/ri";
import { SlLock } from "react-icons/sl";
import { TbShieldLock } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";

const Service1 = ({ item }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const iconMapping = {
    RiUser3Line: RiUser3Line,
    SlLock: SlLock,
    TbShieldLock: TbShieldLock,
    PiHandshakeLight: PiHandshakeLight,
  };
  const IconComponent = iconMapping[item.icon];

  return (
    <Card className="p-3 mx-2 service1">
      <IconComponent size={"50"} />
      <Card.Body className="p-0 mt-4 res-medium-text">
        <Card.Title className="poppins-semiBold  ">{item.heading}</Card.Title>
        <p className=" mt-3 poppins-regular ">{item.detail}</p>
      </Card.Body>
    </Card>
  );
};

export default Service1;
