import React from "react";
import SiteMasterLayout from "../../masterLayout/SiteMasterLayout";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import carousal1 from "../../../assets/images/certified_gaurd.webp";
import h2 from "../../../assets/images/security/h2.jpg";
import gaurds from "../../../assets/images/about1.jpg";
import h22 from "../../../assets/images/h22.jpg";

const CertifiedGuard = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <SiteMasterLayout>
      <Container fluid className="p-0 m-0">
        <Container
          fluid
          className="background-container poppins-semiBold mb-5"
          style={{
            backgroundImage: `url(${gaurds})`,
          }}
        >
          <h1 className="pt-5 text-overlay ">Certified Gaurds</h1>
          <p className="pt-1 text-overlay poppins-medium footer-link-text ">
            Ensuring Your <span className="txt-yellow"> Security </span> with
            Unwavering <br />
            <span className="txt-yellow"> Confidence & Genuine </span>
            Care
          </p>
        </Container>
        <Row className={`mb-5 py-4 content-container `}>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`d-flex justify-content-center align-items-center `}
          >
            <Image
              fluid
              src={h22}
              alt="Image"
              className=""
              style={{ height: "100%", width: "100%", objectFit: "fill" }}
            />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`res-text ${isDesktopOrLaptop && "ps-4"}  `}
          >
            <p style={{ letterSpacing: "2px" }}>SCREENING OF</p>
            <h1 className="poppins-semiBold my-3">Security Officers </h1>
            <h6 className="poppins-medium my-3">
              Each of our guards has to participate in a stringent test and
              receive a pass rate before they are employed.
            </h6>
            <h5 className="poppins-semiBold my-3">Our test includes:</h5>
            <p className="poppins-regular">
              Active training for monitoring and assessing dangerous situations
              to take corrective actions. We design our tests to access each
              guard’s critical thinking using logic and reasoning to identify
              the strengths and weaknesses of alternative solutions and approach
              to problems. Active training for understanding the implications of
              new information for both current and future problem solving and
              decision making.
            </p>
          </Col>
        </Row>
      </Container>
    </SiteMasterLayout>
  );
};

export default CertifiedGuard;
