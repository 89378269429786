import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Image,
  Nav,
  Navbar,
  Stack
} from "react-bootstrap";
import { Link } from "react-router-dom";
import securitylogo from "../../assets/images/security-logo.png";

// import { FaPhone } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import SchedualAppointment from "../Modals/SchedualAppointment";

function Header(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // alert(`width ${window.innerWidth}  height ${window.innerHeight}`);
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        fixed={isScrolled ? "top" : "none"}
        expand="lg"
        className="bg-body-light m-0 p-0 d-flex flex-column"
        style={{
          boxShadow: " 0 5px 15px rgba(0, 0, 0, 0.06)",
          backgroundColor: "white",
        }}
        onToggle={(expanded) => {
          setIsMenuOpen(expanded);
        }}
      >
        <Container
          fluid
          className="m-0 bg-dark-blue d-flex justify-content-center justify-content-lg-end justify-content-md-end"
          style={{
            transition: "all 0.3s ease",
            paddingInline: isDesktopOrLaptop ? "115px" : "20px",
            paddingBlock: "10px",
          }}
        >
          <Stack gap={5} direction="horizontal">
            <h6 className="poppins-regular m-0 ">
              <a
                className="link-text"
                href="mailto:info@cornwallsecuritygroup.com"
              >
                <FiMail size={18} className="me-3" />
                info@cornwallsecuritygroup.com
              </a>
            </h6>
            <h6 className="poppins-regular m-0 ">
              <a className="link-text" href="tel:9547021182">
                <FaPhone size={18} className="me-3" />
                954 702 1182
              </a>
            </h6>
          </Stack>
        </Container>
        <Container
          fluid
          className="m-0 "
          style={{ paddingInline: isDesktopOrLaptop ? "85px" : "20px" }}
        >
          <Navbar.Brand as={Link} to={"/"}>
            <Image
              alt="Logo"
              src={securitylogo}
              onMouseOver={() => {}}
              onMouseOut={() => {}}
              className="navbar-image"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav " />
          <Navbar.Collapse id="responsive-navbar-nav" className="">
            <Nav className="ms-auto">
              <Nav.Link
                className="poppins-regular navbar-item"
                as={Link}
                to="/"
                active={window.location.pathname === "/"}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className="poppins-regular navbar-item "
                as={Link}
                to="/about-us"
                active={window.location.pathname === "/about-us"}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className="poppins-regular navbar-item"
                as={Link}
                to={"/services"}
                active={window.location.pathname === "/services"}
              >
                Our Services
              </Nav.Link>
              <Nav.Link
                className="poppins-regular navbar-item"
                as={Link}
                to={"/certified-guard"}
                active={window.location.pathname === "/certified-guard"}
              >
                Certified Guards
              </Nav.Link>
              {/* <Nav.Link
                className="poppins-regular navbar-item"
                as={Link}
                to={"/contact-us"}
              >
                Contact Us
              </Nav.Link> */}

              <Button
                variant="warning"
                className="poppins-semiBold navbar-button-text "
                style={{ marginBlock: !isDesktopOrLaptop && "10px" }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Schedule an appoinment
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <SchedualAppointment
          isVisible={showModal}
          hideModel={() => {
            setShowModal(false);
          }}
        />
      </Navbar>
    </>
  );
}

export default Header;
