import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Sidebar from "../../../components/Admin/Sidebar";
import AppNavbar from "../AppNavbar";

const MasterLayout = ({ children }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 475px)" });

  useEffect(() => {}, []);

  return (
    <Container fluid className="m-0 p-0" style={{ backgroundColor: "#ffffff" }}>
      {isMobile && <AppNavbar />}
      <Row className="m-0 p-0">
        {isDesktopOrLaptop && (
          <Col className="m-0" xl={3} lg={3} md={3}>
            <Sidebar />
          </Col>
        )}
        <Col
          xl={isMobile ? 12 : 9}
          lg={isMobile ? 12 : 9}
          md={isMobile ? 12 : 9}
          style={{ paddingTop: isDesktopOrLaptop && "50px" }}
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default MasterLayout;
