import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withSwal } from "react-sweetalert2";
import "../../../App.css";
import "../../../assets/css/AdminStyles.css";
import "../../../assets/css/fontStyles.css";

import MasterLayout from "../master/MasterLayout";
import axios from "axios";

const ServiceManagement = ({ swal }) => {
  //

  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(0);
  const [allServices, setAllServices] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);

  const navigate = useNavigate();
  const imageRef = useRef(null);

  const addService = () => {
    const formData = new FormData();
    formData.append("title", title); // Append the image file
    formData.append("description", description);
    formData.append("image", image); // Append the image file

    axios
      .post("https://admin.cornwallsecuritygroup.com/api/services/store", formData, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data", //the token is a variable which holds the token
        },
      })
      .then((resp) => {
        setTitle("");
        setDescription("");
        imageRef.current.value = null;
        setImage(null);
        console.log(resp.data.services);
        getAllServices(accessToken);
      })
      .catch((err) => {
        setErrorMessages(err.response.data.error);
        setLoading(false);
      });
  };

  const updateService = () => {
    const formData = new FormData();
    formData.append("service_id", selectedServiceId);
    formData.append("title", title); // Append the image file
    formData.append("description", description);
    formData.append("image", image); // Append the image file

    axios
      .post("https://admin.cornwallsecuritygroup.com/api/services/update", formData, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data", //the token is a variable which holds the token
        },
      })
      .then((resp) => {

        console.log(resp.data);
        setTitle("");
        setDescription("");
        imageRef.current.value = null;
        setImage(null);
        setShowUpdateBtn(false);
        getAllServices(accessToken);
      })
      .catch((err) => {
        setErrorMessages(err.response.data.error);
        setLoading(false);
      });
  };

  const getAllServices = (token) => {
    axios
      .get("https://admin.cornwallsecuritygroup.com/api/services/getServices", {
        headers: {
          Authorization: "Bearer " + token, //the token is a variable which holds the token
        },
      })
      .then((resp) => {
        console.log(resp.data.services);
        setLoading(false);
        setAllServices(resp.data.services);
      })
      .catch((err) => {
        setErrorMessages(err.response.data.error);
        setLoading(false);
      });
  };

  const deleteService = (serviceId) => {
    console.log(accessToken);
    axios
      .post(
        "https://admin.cornwallsecuritygroup.com/api/services/destroy",
        { service_id: serviceId },
        {
          headers: {
            Authorization: "Bearer " + accessToken, //the token is a variable which holds the token
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        setLoading(false);
        getAllServices(accessToken);
      })
      .catch((err) => {
        setErrorMessages(err.response.data.error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("admin") == null) {
      navigate("/");
    } else {
      const admin = JSON.parse(localStorage.getItem("admin"));
      setAccessToken(admin.access_token);
      getAllServices(admin.access_token);
      setPageLoading(false);
    }
  }, []);

  return pageLoading ? (
    <Row className="d-flex justify-content-lg-center">
      <Spinner style={{ width: "50px", height: "50px", marginTop: "20%" }} />
    </Row>
  ) : (
    <MasterLayout>
      <Container fluid className="pt-5">
        <h3 className="poppins-bold Heading-Text">Service Management</h3>
        <Row className="justify-content-center justify-content-center">
          <Container fluid className="py-3 px-0 ">
            <Row className="g-3">
              <Col xs={12} md={6} lg={6} className="mt-5">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Text className="poppins-bold">Service Title</Form.Text>
                  <Form.Control
                    className="poppins-regular"
                    type="text"
                    size="lg"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6} className="mt-5">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Text className="poppins-bold">
                    Service Description
                  </Form.Text>

                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"
                  ></textarea>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6} className="mt-5">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Text className="poppins-bold">
                    Service Thumbnail
                  </Form.Text>

                  <Form.Control
                    ref={imageRef}
                    className="poppins-regular"
                    type="file"
                    placeholder="************"
                    size="lg"
                    onChange={(e) => setImage(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-5">
                {loading ? (
                  <div style={{ marginLeft: "10%", marginTop: "5%" }}>
                    <Spinner animation="border" variant="dark" />
                  </div>
                ) : showUpdateBtn ? (
                  <Button
                    onClick={() => {
                      if (title == "") {
                        setErrorMessages("Service title is required");
                      } else if (description == "") {
                        setErrorMessages("Describe service");
                      } else {
                        setLoading(true);
                        updateService();
                      }
                    }}
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    className="poppins-semiBold  mt-4 mx-3"
                    style={{ width: "50%" }}
                  >
                    Update Service
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (title == "") {
                        setErrorMessages("Service title is required");
                      } else if (description == "") {
                        setErrorMessages("Describe service");
                      } else if (image == null) {
                        setErrorMessages("Service thumbnail required");
                      } else {
                        setLoading(true);
                        addService();
                      }
                    }}
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    className="poppins-semiBold  mt-4 mx-3"
                    style={{ width: "50%" }}
                  >
                    Add Service
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
          {errorMessages != "" ? (
            <ToastContainer
              position="top-end"
              className="p-3"
              style={{ zIndex: 1 }}
            >
              <Toast
                bg="danger"
                onClose={() => {
                  setErrorMessages("");
                }}
                show={errorMessages == "" ? false : true}
                animation={true}
              >
                <Toast.Header>
                  <strong className="me-auto poppins-bold">IMPORTANT</strong>
                </Toast.Header>
                <Toast.Body className="poppins-regular text-light">
                  {errorMessages}
                </Toast.Body>
              </Toast>
            </ToastContainer>
          ) : null}
        </Row>

        <h3 className="poppins-bold Heading-Text my-5">All Services</h3>

        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th>Thumbnail</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allServices.map((item) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>
                    <a
                      href={`https://admin.cornwallsecuritygroup.com/storage/${item.image}`}
                      target="_blank"
                    >
                      <Image
                        style={{ width: 50, height: 50, borderRadius: "100px" }}
                        src={`https://admin.cornwallsecuritygroup.com/storage/${item.image}`}
                      />
                    </a>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        setSelectedServiceId(item.id);
                        setTitle(item.title);
                        setDescription(item.description);
                        setShowUpdateBtn(true);
                      }}
                      type="submit"
                      variant="outline-warning"
                      size="sm"
                      className="poppins-semiBold  mt-4 mx-3"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        deleteService(item.id);
                      }}
                      type="submit"
                      variant="outline-danger"
                      size="sm"
                      className="poppins-semiBold  mt-4 mx-3"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </MasterLayout>
  );
};

export default withSwal(ServiceManagement);
