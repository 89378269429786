import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Image, Row, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import h1 from "../../../assets/images/h1.jpg";
import h11 from "../../../assets/images/h11.jpg";
import SiteMasterLayout from "../../masterLayout/SiteMasterLayout";
import axios from "axios";
import gaurds from "../../../assets/images/about3.jpg";

const Home = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [isScrolled, setIsScrolled] = useState(false);

  const [allServices, setAllServices] = useState([]);

  const getAllServices = () => {
    axios({
      method: "get",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/getAllServices`,
    })
      .then((response) => {
        console.log(response);
        console.log("//////////////////////////");
        console.log(response.data.services);

        setAllServices(response.data.services);

        console.log("//////////////////////////");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllServices();
    // alert(`width ${window.innerWidth}  height ${window.innerHeight}`);
    const handleScroll = () => {
      if (window.scrollY >= 250) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <SiteMasterLayout>
      <Container fluid className=" p-0 m-0">
        <Container
          fluid
          className="background-container poppins-semiBold "
          style={{
            backgroundImage: `url(${gaurds})`,
          }}
        >
          <h1 className="pt-5 text-overlay ">About Us</h1>
          <p className="pt-1 text-overlay poppins-medium footer-link-text ">
            Ensuring Your <span className="txt-yellow"> Security </span> with
            Unwavering <br />
            <span className="txt-yellow"> Confidence & Genuine </span>
            Care
          </p>
        </Container>
        <Container fluid className="content-container py-5">
          <Row className="py-4">
            <Col className="res-small-text">
              <h2 className="poppins-semiBold">
                <span className="txt-yellow">About</span> Our Company
              </h2>
              <h6 className="poppins-medium txt-gray mt-2 ">
                Detect Deter Defend our clients Asset
              </h6>
              <p className=" pt-3 poppins-regular">
                The Mission of Cornwall Security Group; LLC is to achieve the
                highest quality standards in the security industry. The board at
                Cornwall Security Group LLC is committed and dedicated to secure
                and provide our clients with a healthy working environment free
                of theft, unauthorized solicitation plus a clear sense of
                workplace comfort. Our core values are designed around trust,
                integrity and professionalism. ​
              </p>
            </Col>
            {/* <Col className="px-5">
              <Image fluid src={h1} alt="Image" />
            </Col> */}
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className={`d-flex justify-content-center align-items-center mt-5 `}
            >
              <Container
                style={{
                  // height: "250px",
                  // width: "400px",
                  height: "320px",
                  width: "460px",
                  backgroundColor: "#FAB909",
                  position: "relative",
                }}
              >
                <Image
                  fluid
                  src={h11}
                  alt="Image"
                  style={{
                    position: "absolute",
                    left: "20px",
                    bottom: "20px",
                    height: "320px",
                    width: "460px",
                    objectFit: "cover",
                  }}
                />
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="content-container bg-blue py-5 txt-white">
          <h2 className=" poppins-semiBold">Professional Services</h2>
          <p className="w-50">
            We provide branded officers,{" "}
            <span className="txt-yellow">
              Security Risk Assessment, Security Consultation
            </span>{" "}
            , On-site guard{" "}
            <span className="txt-yellow">(armed or unarmed) </span> , security
            cameras monitoring service
          </p>
          <Row className="pt-3">
            {allServices.map((service, index) => (
              <Col key={index} className="p-2">
                <Badge bg="yellow" text="light" className="badge-style">
                  {service.title}
                </Badge>
              </Col>
            ))}
          </Row>
        </Container>
        <Container fluid className="content-container py-5">
          <Row className="py-5 res-small-text">
            <h3 className="poppins-semiBold">
              Trusted Security by
              <span className="txt-yellow"> Cornwall Security </span>Group
            </h3>
            <h6 className="poppins-medium txt-gray mt-2">
              Your Safety, Our Commitment
            </h6>
            <p className="poppins-regular pt-3">
              1- Cornwall Security Group; LLC has been providing excellent
              security service in{" "}
              <span className="txt-yellow">
                South Florida, across West Palm Beach, Broward, Miami – Dade
              </span>{" "}
              and their surrounding counties with our team of experience
              officers. Competitive pricing, superior quality and exceptional
              customer service are guaranteed when we provide our services.
              <br />
              <br />
              2- Cornwall Security Group; LLC is{" "}
              <span className="txt-yellow">fully licensed and insured </span>
              throughout the{" "}
              <span className="txt-yellow">State of Florida</span> . Our team of
              officers has extensive training in law enforcement, military and
              private security services.
              <br />
              <br />
              3- Cornwall Security Group; LLC employs numerous security guards
              that can handle just about any client’s request in the field of
              security service that would require unarmed or armed security
              guards. Our officers are{" "}
              <span className="txt-yellow">courteous and professional</span> in
              their line of duty.
              <br />
              <br />
              Cornwall Security Group; LLC also offers Courier protection and
              personal Bodyguard services. If you would like more information
              please call our office at{" "}
              <span className="poppins-semiBold">(954) 702-1182</span> You can
              also email us at{" "}
              <span className="poppins-semiBold">
                info@cornwallsecuritygroup.com
              </span>
              .
            </p>
          </Row>
        </Container>
      </Container>
    </SiteMasterLayout>
  );
};

export default Home;
