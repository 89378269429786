import React from "react";
import ContactForm from "../../../components/Forms/ContactForm";
import SiteMasterLayout from "../../masterLayout/SiteMasterLayout";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import gaurds from "../../../assets/images/about-us.jpg";
const Contactus = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <SiteMasterLayout>
      <Container
        fluid
        className="background-container poppins-semiBold mb-5"
        style={{
          backgroundImage: `url(${gaurds})`,
        }}
      >
        <h1 className="pt-5 text-overlay ">Contact Us</h1>
        <p className="pt-1 text-overlay poppins-medium footer-link-text ">
          Ensuring Your <span className="txt-yellow"> Security </span> with
          Unwavering <br />
          <span className="txt-yellow"> Confidence & Genuine </span>
          Care
        </p>
      </Container>
      <Container fluid className={` my-5  content-container `}>
        <Row className="">
          <Col xs={12} md={12} lg={6} className={`  bg-light p-4`}>
            <ContactForm />
          </Col>
          <Col
            xs={12}
            md={12}
            lg={6}
            className={`   d-flex flex-column align-items-center`}
          >
            <Card
              className=" mx-4 mt-4 py-3 px-4 border-0 d-flex flex-row bg-light"
              style={{ width: "24rem" }}
            >
              <Container
                className="d-flex align-items-center w-auto  rounded-circle p-4"
                style={{ backgroundColor: "ThreeDFace" }}
              >
                <FaPhone
                  size={"26"}
                  className="align-self-center "
                  color="#020E2A"
                />
              </Container>

              <Card.Body className="p-1 mt-3 mx-3  res-medium-text d-flex flex-column align-items-start justify-content-center">
                <Card.Subtitle className="poppins-medium ">
                  Phone No
                </Card.Subtitle>
                {/* <p className="poppins-regular ">954 702 1182</p> */}
                <a className="poppins-regular" href="tel:9547021182">
                  954 702 1182
                </a>
              </Card.Body>
            </Card>
            <Card
              className=" mx-4 mt-4 py-3 px-4 border-0 d-flex flex-row bg-light"
              style={{ width: "24rem" }}
            >
              <Container
                className="d-flex align-items-center w-auto  rounded-circle p-4 justify-content-center"
                style={{ backgroundColor: "ThreeDFace" }}
              >
                <IoMdMail
                  size={"26"}
                  className="align-self-center "
                  color="#020E2A"
                />
              </Container>

              <Card.Body className="p-1 mt-3 mx-3  res-medium-text d-flex flex-column align-items-start justify-content-center">
                <Card.Subtitle className="poppins-medium ">
                  Email Address
                </Card.Subtitle>
                <a
                  className="poppins-regular"
                  href="mailto:info@cornwallsecuritygroup.com"
                >
                  info@cornwallsecuritygroup.com
                </a>
                {/* <p className="poppins-regular ">
                  info@cornwallsecuritygroup.com
                </p> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </SiteMasterLayout>
  );
};

export default Contactus;
